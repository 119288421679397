<template>
	<div
		ref="episode_list_item_view"
		class=" size-px-14 color-gray02 flex-column" :class="items.length > 0 ? 'pb-30' : 'full-height'"
	>
		<div class="mt-50 ">
			<div>
				<VideoJs
					v-if="item_video.cover.src"
					:item="item_video"
					:id="item_video.bulletin_number + 'view'"
					class="width-100"
				></VideoJs>
			</div>
			<div
				class="pa-20 color-gray-dark02 font-weight-500 size-px-16"
			>
				{{ item_series.title }}

				<div class="mt-15 size-px-14 color-blue-mafia justify-start items-center gap-5"><span class="badge_30 mr-10" :class="'badge_30_' + item_cartel.type + '_bl'"></span>{{ item_cartel.cartl_name }}</div>
			</div>
		</div>
		<div class="full-height flex-column ">
			<div class="pa-10-20">
				<hr class="under-line" />
			</div>

			<div
				v-if="items.length > 0"
				class=" full-height flex-column "
				:class="view_type == 'episode' ? 'mt-20' : ''"
			>
				<div class="prl-20 size-px-16 color-gray-dark02 font-weight-500 justify-space-between">
					{{ $language.tv.title_tv_episode}}<!-- 에피소드 -->
					<button
						v-if="mode != 'sort'"
						class="size-px-13 font-weight-400"
						@click="mode = 'sort'"
					>{{ $language.tv.title_tv_episode_sort}} <!-- 순서 변경 --></button>
				</div>
				<ul class="mt-20  full-height ">

					<draggable
						v-model="items"
						@start="setAction"
						@end="is_action = ''"
						handle=".handle"
					>
					<li
						v-for="(item, index) in list_items"
						:key="'item_' + index"
						class="mb-5 prl-20 ptb-5 handle"
						:class="{ 'bg-gray-light' : is_action === index}"
					>
						<div
							class="justify-space-between gap-10" style="height: 80px"
						>
							<div class=" position-relative position-relative ">
								<template
									v-if="view_type == 'series'"
								>
									<div class="box radius-10 box-120-80" @click="toEpisode(item)">
										<img :src="item.cover_img" class="object-cover" @error="$bus.$emit('onErrorImage', $event)" />
									</div>
								</template>

								<template
									v-else
								>
									<div
										v-if="item.post_file_list[1] && item.post_file_list[1].post_appendix_file_transform_info && item.post_file_list[1].post_appendix_file_transform_info.transform_error_code == 'CA00900001'"
										class="position-absolute-full flex-column justify-center items-center text-white"
									>
										<div class="position-absolute-full" :class="'shorts-status-' + item.post_state_code"></div>
										<div class="position-relative text-center">
											<img :src="require('@/assets/image/icon_clock.svg')" />
											<div class="mt-10">{{ $language.shorts.subject_ing}} <!-- 게시중 --></div>
										</div>
									</div>
									<div
										v-else-if="item.post_file_list[1] && item.post_file_list[1].post_appendix_file_transform_info && item.post_file_list[1].post_appendix_file_transform_info.transform_error_code == 'LE00100003'"
										class="position-absolute-full flex-column justify-center items-center text-red"
										@click="onDelete(item)"
									>
										<div class="position-absolute-full" :class="'shorts-status-' + item.post_state_code"></div>
										<div class="position-relative text-center">
											<img :src="require('@/assets/image/icon_close_circle_red.svg')" />
											<div class="mt-10 color-red">{{ $language.shorts.subject_fail}} <!-- 게시 실패 --></div>
										</div>
									</div>
									<div
										v-else
										class="box radius-10 box-120-80"
										@click="toDetail(item)"
									>
										<img :src="item.post_file_list[0].post_appendix_file_org_url" class="object-cover" @error="$bus.$emit('onErrorImage', $event)" />
									</div>

								</template>
							</div>
							<div class="flex-1 flex-column  full-height">
								<div class="size-px-13 full-height font-weight-500 color-gray-dark02 ">{{ item.title | maxLength(32, '...')}}</div>
								<div class="mt-auto size-px-12">{{ $date.getTimeStory(item.registdate)}}</div>
							</div>
							<div
								:class="mode == 'sort' ? 'full-height flex-column justify-center' : ''"
							>
								<button
									v-if="mode == 'sort'"
								><v-icon class="color-bbb ">mdi-menu</v-icon></button>
								<button
									v-else
									class=""
									@click="onMore(item)"
								><img :src="require('@/assets/image/btn_postset.png')" class="width-50"/></button>
							</div>
						</div>
					</li>
					</draggable>
					<li
						v-if="mode == 'sort'"
						class="text-center prl-20 mt-30"
					>
						<div class="btn_wrap">
							<button
								class="btn_l btn_fill_blue "
								@click="postSort"
							>확인</button>
						</div>
					</li>
				</ul>
			</div>
			<Empty
				v-else

				class="full-height"
			></Empty>
		</div>

		<div style="position: fixed; right: 20px; bottom: 20px;">
			<button
				v-if="mode != 'sort'"
				@click="toItem"
			>
				<div class="cartel_write_btn">
					<span class="hide">{{ $language.word.write }}</span>
				</div>
			</button>
		</div>

		<PopupHalf
			v-if="is_on_more"
			:user="user"
			:title="title_half"

			@cancel="is_on_more = false"
		>
			<template
				v-slot:half_items
			>
				<li class="pb-10" @click="toEdit">{{ $language.common.modify }} <!--  수정 --></li>
				<li @click="onDelete()">{{ $language.common.delete }} <!-- 삭제 --></li>
			</template>
		</PopupHalf>

		<Popup_confirm
			v-if="is_on_delete"

			@cancel="is_on_delete = false"
			@click="postDelete"
		>
			<template v-slot:title>{{ $language.confirm.delete_bbs.title }}</template>
			<template
				v-slot:main-txt
			>{{ $language.confirm.delete_bbs.main }}</template>
			<template
				v-slot:sub-txt
			>{{ $language.confirm.delete_bbs.sub }}</template>

		</Popup_confirm>
		<!-- 게시물 상세보기 -->
		<cartel_bbs_detail
			v-if="is_item_detail"

			:user="user"
			:cartel="item_cartel"
			:bbs="item_detail"
			:is_item_detail_comment="is_item_detail_comment"

			class="layer-popup"

			@cancel="offDetail"
		></cartel_bbs_detail>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import Empty from "@/view/Layout/Empty";
import PopupHalf from "@/view/Layout/PopupHalf";
import Popup_confirm from "@/view/Layout/PopupConfirm";
import VideoJs from "@/components/VideoJs";
import cartel_bbs_detail from "@/view/Cartel/cartel_bbs_detail";
export default {
	name: 'MafiaTvEpisode'
	, components: {cartel_bbs_detail, VideoJs, Popup_confirm, PopupHalf, Empty, draggable}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'MafiaTvEpisode'
				, title: this.$language.tv.title_tv_series_detail
				, type: 'cartel_sub'
				, not_footer: true
			}
			, item_search: {
				srchtext: ''
				, page_number: 1
				, pagerecnum: 10
			}
			, items: [

			]
			, item_more: {}
			, is_on_more: false
			, is_on_delete: false
			, title_half: this.$language.notice.article
			, view_type: 'episode'
			, item_search_episode: {
				page_number: 1
				, pagerecnum: 10
			}
			, item_series: {

			}
			, item_video: {
				video: {
					src: ''
				}
				, cover: {
					src: ''
				}
			}
			, item_cartel: {

			}
			, mode: ''
			, is_action: ''
			, is_item_detail: false
			, item_detail: {}
			, is_item_detail_comment: false
		}

	}
	, computed: {
		list_items: function(){
			return this.items.filter( (item) => {
				if(this.view_type == 'series'){
					item.cover_img = item.post_file_list[0].post_appendix_file_org_url
				}else{
					item.post_file_list.filter( (file) => {
						if(file.post_appendix_file_div_code == 'CA01000001'){
							item.cover_img = file.post_appendix_file_org_url
						}
					})
				}
				return item
			})
		}
		, post_sort_list: function(){
			let t = []
			this.items.filter( (item, index) => {
				t.push({
					bulletin_number: item.bulletin_number
					, sort_seqen: index + 1
				})
			})
			return t
		}
	}
	, methods: {
		getData: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_tv_episode_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, parents_post_number: this.$route.params.p_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.item_series = result.data.parents_post_info
					this.items = result.data.post_list
					this.items = result.data.post_list
					this.item_video.cover.src = this.item_series.post_file_list[0].post_appendix_file_org_url
					if(this.items.length > 0){
						let files = this.items[0].post_file_list
						for(let i = 0; i < files.length; i++){
							let file = files[i]
							if(file.post_appendix_file_div_code == 'CA01000001'){
								// this.item_video.cover.src = file.post_appendix_file_org_url
							}else{
								// this.item_video.video.src = file.post_appendix_file_org_url
							}
						}
					}
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onMore: function(item){
			this.is_on_more = true
			this.item_more = item
		}
		, toEdit: function(){
			this.$bus.$emit('to', { name: 'MafiaTvEdit', params: {idx: this.$route.params.idx, b_code: this.$route.params.b_code, b_id: this.$route.params.b_id, bbs_id: this.item_more.bulletin_number}})
		}
		, onDelete: function(item){
			if(item){
				this.item_more = item
			}
			this.is_on_delete = true
		}
		, postDelete: async function(){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_delete_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.item_more.board_number
						, bulletin_number: this.item_more.bulletin_number
					}
					, type: true
				})

				if (result.success) {
					await this.getData()
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
				this.is_on_delete = false
				this.is_on_more = false
			}
		}
		, toItem: function(){

			switch (this.view_type){
				case 'series':
					this.$emit('to', {name: 'MafiaTvSeries', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, type: this.view_type}})
					break;
				case 'alone':
					this.$emit('to', {name: 'MafiaTvItem', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, type: this.view_type}})
					break;
				case 'episode':
					this.$emit('to', {name: 'MafiaTvItem', params: {idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, type: this.view_type, p_id: this.item_series.bulletin_number}})

					break;
			}
		}
		, getEpisode: async function(series){
			try {

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_tv_episode_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: series.board_number
						, parents_post_number: series.bulletin_number
						, page_number: this.item_search_episode.page_number
						, pagerecnum: this.item_search_episode.pagerecnum
					}
					, type: true
				})

				if (result.success) {
					this.items = result.data.post_list
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
				this.is_on_delete = false
				this.is_on_more = false
			}
		}
		, toEpisode: function(item){
			this.view_type = 'episode'
			this.item_series = item
			this.$bus.$emit('to', { name: 'MafiaTvManageList', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id, p_id: this.item_series.bulletin_number }})
			this.program.from = ''
			this.getEpisode(item)
		}
		, getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setAction: function ($e){
			console.log('e', $e)
			this.is_action = $e.oldIndex
		}
		, postSort: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_tv_episod_sort
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, parents_post_number: this.$route.params.p_id
						, post_list: this.post_sort_list
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.mode = ''
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			this.is_item_detail = true
			this.item_detail = item
			// this.$bus.$emit('to', {name: 'MafiaTvList', params: {idx: this.$route.params.idx, b_id: this.$route.params.b_id, bbs_id: item.bulletin_number}, hash: 'detail'})
		}
		, offDetail: function(){
			this.is_item_detail = false
			this.item_detail = {}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getCartel()
		this.getData()
	}
}
</script>

<style>
.box-120-80 {
	width: 120px; height: 80px;
	border-radius: 8px;
}
.box-130-70 {
	width: 130px; height: 70px;
	border-radius: 8px;
}

.white-space {
	width: 120px;
	white-space: normal;
	text-overflow: ellipsis;
	word-break: break-all;
	overflow: hidden
}


.btn-tv{
	width: 62px;
	height: 30px;
	padding: 9px 14px;
	display: flex;
	justify-content: center;
	align-items: center;

	color: var(--blue02, #293097);
	text-align: center;
	font-family: "Noto Sans CJK KR";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 12px */
}

.btn-blue {
	background-color: var(--blue02);
	color: var(--white);
}
.btn-blue-outline {
	border: 1px solid var(--blue02);
	color: var(--blue02);
}
</style>